import { renderHook } from '@testing-library/react-hooks';
import { useCpfValidation } from '../../hooks/cpfValidation';
import { __ } from '../../../../../../i18n.js';

const invalidCpfMessage = __('Invalid document number');

describe('useCpfValidation', () => {
  it('should return no error for a valid CPF number', () => {
    const { result } = renderHook(() => useCpfValidation('123.456.789-09'));
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('123.456.789-09');
  });

  it('should return an error for an invalid CPF number', () => {
    const { result } = renderHook(() => useCpfValidation('123.456.789-00'));
    expect(result.current.error).toBe(invalidCpfMessage);
    expect(result.current.value).toBe('123.456.789-00');
  });

  it('should return no error for an empty CPF number', () => {
    const { result } = renderHook(() => useCpfValidation(''));
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('');
  });

  it('should return no error for a partially filled CPF mask', () => {
    const { result } = renderHook(() => useCpfValidation('123.456.___-__'));
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('123.456.___-__');
  });

  it('should return no error for a CPF with only underscores', () => {
    const { result } = renderHook(() => useCpfValidation('___._.___-__'));
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('___._.___-__');
  });
});
