import { __ } from '../../../../../i18n.js';

/**
 * Custom React hook to validate a CPF number.
 *
 * @param {string} cpf - The CPF number to validate.
 * @returns {{value: string, error: string}} - An object containing the CPF value and an error message if invalid.
 *
 * This hook checks if the provided CPF number is valid using the CPF validation algorithm.
 * It returns an object with the CPF value and an error message if the CPF number is invalid.
 * The error message is an empty string if the CPF number is valid.
 */
export function useCpfValidation(cpf) {
  const isValidCpf = (cpf) => {
    cpf = cpf.replace(/\D/g, '');
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

    const calcCheckDigit = (cpf, factor) => {
      let total = 0;
      for (let i = 0; i < factor - 1; i++) {
        total += parseInt(cpf[i], 10) * (factor - i);
      }
      const remainder = (total * 10) % 11;
      return remainder === 10 ? 0 : remainder;
    };

    const digit1 = calcCheckDigit(cpf, 10);
    const digit2 = calcCheckDigit(cpf, 11);

    return digit1 === parseInt(cpf[9], 10) && digit2 === parseInt(cpf[10], 10);
  };

  const error =
    cpf.replace(/\D/g, '').length === 11 && !isValidCpf(cpf)
      ? __('Invalid document number')
      : '';
  return { value: cpf, error };
}
