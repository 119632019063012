import { renderHook } from '@testing-library/react-hooks';
import { useEmailValidation } from '../../hooks/emailValidation';
import { __ } from '../../../../../../i18n.js';

const invalidEmail = __('Invalid email');

describe('useEmailValidation', () => {
  it('should return no error for a valid email', () => {
    const { result } = renderHook(() => useEmailValidation('test@example.com'));
    expect(result.current).toEqual({ value: 'test@example.com', error: '' });
  });

  it('should return an error for an invalid email', () => {
    const { result } = renderHook(() => useEmailValidation('invalid-email'));
    expect(result.current).toEqual({
      value: 'invalid-email',
      error: invalidEmail,
    });
  });

  it('should return no error for an empty email', () => {
    const { result } = renderHook(() => useEmailValidation(''));
    expect(result.current).toEqual({ value: '', error: '' });
  });

  it('should return an error for an email with spaces', () => {
    const { result } = renderHook(() =>
      useEmailValidation('test @example.com'),
    );
    expect(result.current).toEqual({
      value: 'test @example.com',
      error: invalidEmail,
    });
  });

  it('should return an error for an email without a domain', () => {
    const { result } = renderHook(() => useEmailValidation('test@'));
    expect(result.current).toEqual({ value: 'test@', error: invalidEmail });
  });

  it('should return an error for an email without an "@" symbol', () => {
    const { result } = renderHook(() => useEmailValidation('testexample.com'));
    expect(result.current).toEqual({
      value: 'testexample.com',
      error: invalidEmail,
    });
  });
});
