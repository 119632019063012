import { renderHook } from '@testing-library/react-hooks';
import { useCardDateValidation } from '../../hooks/cardDateValidation';
import { __ } from '../../../../../../i18n.js';

const invalidExpirationDate = __('Invalid expiration date');

describe('useCardDateValidation', () => {
  it('should return no error for a valid expiration date', () => {
    const { result } = renderHook(() => useCardDateValidation('12/25'));
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('12/25');
  });

  it('should return an error for an invalid expiration date', () => {
    const { result } = renderHook(() => useCardDateValidation('13/25'));
    expect(result.current.error).toBe(invalidExpirationDate);
    expect(result.current.value).toBe('13/25');
  });

  it('should return an error for an expired date', () => {
    const { result } = renderHook(() => useCardDateValidation('01/20'));
    expect(result.current.error).toBe(invalidExpirationDate);
    expect(result.current.value).toBe('01/20');
  });

  it('should return no error for an empty expiration date', () => {
    const { result } = renderHook(() => useCardDateValidation(''));
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('');
  });

  it('should handle non-numeric input gracefully', () => {
    const { result } = renderHook(() => useCardDateValidation('ab/cd'));
    expect(result.current.error).toBe(invalidExpirationDate);
    expect(result.current.value).toBe('ab/cd');
  });

  it('should return an error for incomplete date format', () => {
    const { result } = renderHook(() => useCardDateValidation('12/'));
    expect(result.current.error).toBe(invalidExpirationDate);
    expect(result.current.value).toBe('12/');
  });
});
