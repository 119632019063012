import React from 'react';
import { AngleDownLight } from '@classapp-tech/edna-icons';
import {
  SelectFormDiv,
  SelectFormLabel,
  Select,
  Selection,
  SelectIcon,
} from './SelectFormFieldStyle';
import { __ } from '../../../../i18n';

/**
 * SelectFormField component renders a labeled select dropdown with validation and custom options.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.label - The label for the select field.
 * @param {string} props.name - The name attribute for the select field.
 * @param {Array<Object>} props.options - The options for the select dropdown. Each option should have `value`, `label`, and optional `disabled`, `hidden`, and `selected` properties.
 * @param {boolean} props.isReadOnly - Whether the select field is read-only.
 * @param {boolean} props.required - Whether the select field is required.
 * @returns {JSX.Element} The rendered SelectFormField component.
 */
export function SelectFormField({
  label,
  name,
  options,
  isReadOnly = false,
  required = false,
}) {
  return (
    <SelectFormDiv>
      <SelectFormLabel htmlFor={name}>{__(label)}</SelectFormLabel>
      <Selection>
        <Select
          isReadOnly={isReadOnly}
          name={name}
          id={name}
          disabled={isReadOnly}
          required={required}
          defaultValue={options.find((option) => option.selected)?.value}
        >
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              hidden={option.hidden}
            >
              {__(option.label)}
            </option>
          ))}
        </Select>
        <SelectIcon>
          <AngleDownLight />
        </SelectIcon>
      </Selection>
    </SelectFormDiv>
  );
}
