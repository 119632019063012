import { __ } from '../../../../../i18n.js';
import cep from 'cep-promise';

/**
 * @typedef CepInfo
 * @type {Object}
 * @property {string} cep - The CEP value.
 * @property {string} state - The state name.
 * @property {string} city - The city name.
 * @property {string} neighborhood - The neighborhood name.
 * @property {string} street - The street name.
 * @property {string} service - The service used to fetch the address.
 *
 * Custom React hook to validate and fetch address from a CEP.
 *
 * @param {string} cepCode - The CEP (postal code) to validate and fetch the address for.
 * @returns {{
 *   value: string,
 *   error: string,
 *   extraData: Promise<CepInfo|null>
 * }} - An object containing the CEP value,
 * an error message if invalid, and a promise of the fetched address.
 *
 * This hook validates the provided CEP and provides a function to fetch the address.
 * The error message is an empty string if the CEP is valid.
 */
export function useCepInfo(cepCode) {
  const isValidCep = (cep) => /^\d{5}-?\d{3}$/.test(cep);

  const fetchAddress = async (formattedCep) => {
    if (formattedCep.length !== 8) return null;

    try {
      const data = await cep(formattedCep);
      return data;
    } catch {
      return null;
    }
  };

  const formattedCep = cepCode.replace(/-|\_/g, '');
  const error =
    formattedCep && !isValidCep(formattedCep) ? __('Invalid Zip Code') : '';

  return { value: cepCode, error, extraData: fetchAddress(formattedCep) };
}
