import { __ } from '../../../../../i18n.js';

/**
 * Custom React hook to validate a card number.
 *
 * @param {string} cardNumber - The card number to validate.
 * @returns {{value: string, error: string}} - An object containing the card number value and an error message if invalid.
 *
 * This hook checks if the provided card number is valid using the Luhn algorithm.
 * It returns an object with the card number value and an error message if the card number is invalid.
 * The error message is an empty string if the card number is valid.
 */
export function useCardNumberValidation(cardNumber) {
  const luhnCheck = (num) => {
    let sum = 0;
    let shouldDouble = false;
    for (let i = num.length - 1; i >= 0; i--) {
      let digit = parseInt(num[i], 10);
      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      sum += digit;
      shouldDouble = !shouldDouble;
    }
    return sum % 10 === 0;
  };

  const error =
    cardNumber.replace(/\_|\s/g, '').length === 16 &&
    !luhnCheck(cardNumber.replace(/\s/g, ''))
      ? __('Invalid card number')
      : '';
  return { value: cardNumber, error };
}
