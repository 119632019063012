import { renderHook, act } from '@testing-library/react-hooks';
import { useDebounce } from '../../hooks/debounce';

jest.useFakeTimers();

describe('useDebounce', () => {
  jest.spyOn(global, 'clearTimeout');

  beforeEach(() => {
    jest.clearAllTimers();
    jest.clearAllMocks();
  })

  it('should return the initial value immediately', () => {
    const { result } = renderHook(() => useDebounce('test', 500));
    expect(result.current).toBe('test');
    expect(clearTimeout).toHaveBeenCalledTimes(0);
  });

  it('should update the debounced value after the delay', () => {
    const { result, rerender } = renderHook(
      ({ value, delay }) => useDebounce(value, delay),
      { initialProps: { value: 'test', delay: 500 } }
    );

    rerender({ value: 'updated', delay: 500 });

    expect(result.current).toBe('test');

    act(() => {
      jest.advanceTimersByTime(500);
    });

    expect(result.current).toBe('updated');
    expect(clearTimeout).toHaveBeenCalledTimes(1);
  });

  it('should clear the timeout on unmount', () => {
    const { unmount } = renderHook(() => useDebounce('test', 500));

    unmount();

    expect(clearTimeout).toHaveBeenCalledTimes(1);
  });
});