import { __ } from '../../../../../i18n.js';

/**
 * Custom React hook to validate an email address.
 *
 * @param {string} email - The email address to validate.
 * @returns {{value: string, error: string}} - An object containing the email value and an error message if invalid.
 *
 * This hook checks if the provided email address is valid. It returns an object
 * with the email value and an error message if the email is invalid. The error
 * message is an empty string if the email is valid.
 */
export function useEmailValidation(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const error = email && !emailRegex.test(email) ? __('Invalid email') : '';
  return { value: email, error };
}
