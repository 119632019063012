import styled, { css } from 'styled-components';

export const SelectFormLabel = styled.label`
  font-family: Circular Std;
  font-size: 1rem;
  font-weight: 700;
  line-height: 140%;
  color: #212529;
`;

export const SelectFormDiv = styled.div`
  display: flex;
  width: 306px;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Selection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SelectIcon = styled.div`
  left: -2rem;
  top: 0.125rem;
  align-self: center;
  position: relative;
`;

export const Select = styled.select`
  height: 48px;
  width: 100%;
  flex-shrink: 0;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  border-radius: 10px;
  cursor: pointer;
  padding: 0 1rem;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  font-family: Circular Std;
  font-size: 1rem;
  font-weight: 450;

  ${({ isReadOnly }) =>
    isReadOnly &&
    css`
      background-color: #e5e5e5;
      cursor: not-allowed;
      color: #616161;
    `}

  &:focus {
    outline: none;
  }
`;
