import styled, { css } from 'styled-components';

export const PhoneFormDiv = styled.div`
  display: flex;
  width: 306px;
  flex-direction: column;
  gap: 0.5rem;
`;

export const PhoneContent = styled.div`
  display: flex;
  border-radius: 10px;

  ${({ isReadOnly }) =>
    isReadOnly &&
    css`
      background-color: #e5e5e5;
      color: #616161;
    `}
`;

export const PhoneFormLabel = styled.label`
  font-family: Circular Std;
  font-size: 1rem;
  font-weight: 700;
  line-height: 140%;
  color: #212529;
`;

export const PhoneCountrySelection = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`;

export const PhoneSelect = styled.select`
  height: 3rem;
  border-style: none;
  border-radius: 10px;
  width: 100%;
  flex-shrink: 0;
  cursor: pointer;
  padding: 0 1rem;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  font-family: Circular Std;
  font-size: 1rem;
  font-weight: 450;
  text-overflow: ellipsis;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`;

export const PhoneInputDiv = styled.div`
  width: 60%;
  display: flex;
  gap: 0.3rem;
  justify-content: space-between;
  align-items: center;

  padding: 0 0.5rem;

  border-style: solid solid solid none;
  border-width: 1px;
  border-color: #cccccc;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

export const SelectIcon = styled.div`
  left: -1.5rem;
  top: 0.125rem;
  align-self: center;
  position: relative;
`;

export const PhoneIcon = styled.div`
  top: 0.2rem;
  position: relative;
`;

export const PhoneFormInput = styled.input`
  height: 48px;
  width: 100%;
  font-family: Circular Std;
  font-size: 1rem;
  font-weight: 450;
  border-style: none;
  border-radius: 10px;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`;
