import React from 'react';
import { render, fireEvent, screen, act } from '@testing-library/react';
import { TextFormField } from '../TextFormField';

jest.useFakeTimers();

describe('<TextFormField />', () => {
  const mockHook = jest.fn();
  const mockOnChange = jest.fn();

  beforeEach(() => {
    jest.clearAllTimers();
    jest.clearAllMocks();
  });

  afterAll(() => {
    jest.useRealTimers();
  });

  it('renders with label, input, placeholder, required', () => {
    mockHook.mockReturnValue({ value: 'test value', error: null });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        value="test value"
        placeholder="Test Placeholder"
        required={true}
      />,
    );

    act(() => {
      jest.advanceTimersByTime(500);
    });

    expect(screen.getByLabelText('Test Label')).toBeInTheDocument();
    expect(screen.getByPlaceholderText('Test Placeholder')).toBeInTheDocument();
    expect(screen.getByRole('textbox')).toBeInTheDocument();
    expect(screen.getByRole('textbox')).toHaveValue('test value');
    expect(screen.getByRole('textbox')).toHaveAttribute('required');
  });

  it('calls onChange and hook on input change', () => {
    mockHook.mockReturnValue({ value: 'test value', error: null });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        value=""
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'new value' },
      });
    });

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'newest value' },
      });

      jest.advanceTimersByTime(500);
    });

    expect(mockHook).toHaveBeenCalledTimes(2);
  });

  it('does not call onChange or hook when isReadOnly is true', () => {
    mockHook.mockReturnValue({ value: 'test value', error: null });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        value="test value"
        isReadOnly={true}
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'new value' },
      });

      jest.advanceTimersByTime(500);
    });

    expect(mockHook).toHaveBeenCalledTimes(1);
    expect(screen.getByRole('textbox')).toHaveValue('test value');
  });

  it('renders input as readOnly when isReadOnly is true', () => {
    mockHook.mockReturnValue({ value: 'test value', error: null });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        value="test value"
        isReadOnly={true}
      />,
    );

    act(() => {
      jest.advanceTimersByTime(500);
    });

    expect(screen.getByRole('textbox')).toHaveAttribute('readonly');
  });

  it('renders a span when hook return an error', () => {
    mockHook.mockReturnValue({ value: '', error: 'Error from hook' });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        value=""
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'new value' },
      });

      jest.advanceTimersByTime(500);
    });

    expect(mockHook).toHaveBeenCalledTimes(2);
    expect(screen.getByText(/Error from hook/i)).toBeInTheDocument();
  });

  it('displays tooltip when hook returns an error', () => {
    mockHook.mockReturnValue({ value: '', error: 'Error from hook' });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        value=""
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'new value' },
      });

      jest.advanceTimersByTime(500);
    });

    expect(screen.getByText(/Error from hook/i)).toBeInTheDocument();
    expect(mockHook).toHaveBeenCalledTimes(2);
  });

  it('removes error message when hook no longer returns an error', () => {
    mockHook
      .mockReturnValueOnce({ value: '', error: 'Error from hook' })
      .mockReturnValueOnce({ value: 'new value', error: null });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        value=""
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'new value' },
      });
    });

    expect(mockHook).toHaveBeenCalledTimes(1);
    expect(screen.getByText(/Error from hook/i)).toBeInTheDocument();

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'new value' },
      });

      jest.advanceTimersByTime(500);
    });

    expect(mockHook).toHaveBeenCalledTimes(2);
    expect(screen.queryByText(/Error from hook/i)).not.toBeInTheDocument();
  });

  it('tooltip visibility changes on hover', () => {
    mockHook.mockReturnValue({ value: '', error: 'Error from hook' });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        value=""
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'new value' },
      });

      jest.advanceTimersByTime(500);
    });

    const icon = screen.getByRole('img');
    fireEvent.mouseOver(icon);

    expect(screen.getByText(/Error from hook/i)).toHaveStyle(
      'visibility: visible',
    );
  });

  it('does not call hook until debounce time has passed', () => {
    mockHook.mockReturnValue({ value: 'oldest value', error: null });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        value=""
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'old value' },
      });
    });

    expect(mockHook).toHaveBeenCalledTimes(1);

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'new value' },
      });
    });

    expect(mockHook).toHaveBeenCalledTimes(1);

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'newest value' },
      });

      jest.advanceTimersByTime(500);
    });

    expect(mockHook).toHaveBeenCalledTimes(2);
  });

  it('does not allow letters when input mask is configured', () => {
    mockHook.mockReturnValue({ value: 'text value', error: null });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        placeholder="Test Placeholder"
        required={true}
        value=""
        mask="999-999-9999"
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'value' },
      });

      jest.advanceTimersByTime(500);
    });

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'new value' },
      });

      jest.advanceTimersByTime(500);
    });

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'newest value' },
      });

      jest.advanceTimersByTime(500);
    });

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'updated value' },
      });

      jest.advanceTimersByTime(500);
    });

    expect(screen.getByLabelText('Test Label')).toBeInTheDocument();
    expect(screen.getByPlaceholderText('Test Placeholder')).toBeInTheDocument();
    expect(screen.getByRole('textbox')).toBeInTheDocument();
    expect(screen.getByRole('textbox')).toHaveValue('___-___-____');
    expect(screen.getByRole('textbox')).toHaveAttribute('required');
    expect(mockHook).toHaveBeenCalledTimes(2);
  });

  it('allow numbers when input mask is configured', () => {
    mockHook
      .mockReturnValueOnce({ value: 'text value', error: null })
      .mockReturnValueOnce({ value: '1231231234', error: null });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        placeholder="Test Placeholder"
        required={true}
        value=""
        mask="999-999-9999"
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: '1231231234' },
      });

      jest.advanceTimersByTime(500);
    });

    expect(screen.getByLabelText('Test Label')).toBeInTheDocument();
    expect(screen.getByPlaceholderText('Test Placeholder')).toBeInTheDocument();
    expect(screen.getByRole('textbox')).toBeInTheDocument();
    expect(screen.getByRole('textbox')).toHaveValue('123-123-1234');
    expect(screen.getByRole('textbox')).toHaveAttribute('required');
    expect(mockHook).toHaveBeenCalledTimes(2);
  });

  it('calls onChange when input value changes', () => {
    render(
      <TextFormField
        label="Test Label"
        name="testField"
        onChange={mockOnChange}
        value=""
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'value' },
      });

      jest.advanceTimersByTime(500);
    });

    expect(mockOnChange).toHaveBeenCalledTimes(2);
    expect(mockOnChange).toHaveBeenCalledWith(null);
  });

  it('calls onChange when hook returns data', () => {
    mockHook
      .mockReturnValueOnce({ value: '', error: '' })
      .mockReturnValueOnce({
        value: 'hook value',
        error: 'hook error',
        extraData: { raw: 'data' },
      });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        onChange={mockOnChange}
        value=""
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'new value' },
      });

      jest.advanceTimersByTime(500);
    });

    expect(mockHook).toHaveBeenCalledWith('');
    expect(mockHook).toHaveBeenCalledWith('new value');
    expect(mockHook).toHaveBeenCalledTimes(2);

    expect(mockOnChange).toHaveBeenCalledTimes(2);

    expect(mockOnChange).toHaveBeenCalledWith(null);

    expect(mockOnChange).toHaveBeenCalledWith({
      raw: 'data',
    });
  });

  it('does not call onChange when isReadOnly is true', () => {
    mockHook.mockReturnValue({ value: 'test value', error: null });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        onChange={mockOnChange}
        value="test value"
        isReadOnly={true}
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'new value' },
      });

      jest.advanceTimersByTime(500);
    });

    expect(mockOnChange).toHaveBeenCalledTimes(1);
    expect(screen.getByRole('textbox')).toHaveValue('test value');
  });

  it('calls onChange with null when hook returns an error', () => {
    mockHook.mockReturnValue({
      value: '',
      error: 'Invalid input',
    });

    render(
      <TextFormField
        label="Test Label"
        name="testField"
        hook={mockHook}
        onChange={mockOnChange}
        value=""
      />,
    );

    act(() => {
      fireEvent.change(screen.getByRole('textbox'), {
        target: { value: 'invalid value' },
      });

      jest.advanceTimersByTime(500);
    });

    expect(mockHook).toHaveBeenCalledWith('');
    expect(mockHook).toHaveBeenCalledWith('invalid value');
    expect(mockHook).toHaveBeenCalledTimes(2);
    expect(mockOnChange).toHaveBeenCalledWith(null);
  });
});
