import { renderHook, act } from '@testing-library/react-hooks';
import { useCepInfo } from '../../hooks/cepInfo';
import cepPromise from 'cep-promise';
import { __ } from '../../../../../../i18n.js';

jest.mock('cep-promise', () => jest.fn());

const invalidZipCode = __('Invalid Zip Code');

describe('useCepInfo', () => {
  beforeEach(() => {
    jest.clearAllMocks();
  });

  it('should return an error for an invalid CEP format', () => {
    const { result } = renderHook(() => useCepInfo('12345'));
    expect(result.current.error).toBe(invalidZipCode);
    expect(result.current.value).toBe('12345');
    expect(cepPromise).toHaveBeenCalledTimes(0);
  });

  it('should return no error for a valid CEP format', () => {
    const { result } = renderHook(() => useCepInfo('12345-678'));
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('12345-678');
    expect(cepPromise).toHaveBeenCalledTimes(1);
  });

  it('should fetch address successfully for a valid CEP', async () => {
    const mockCepData = {
      cep: '12345-678',
      state: 'State',
      city: 'City',
      neighborhood: 'Neighborhood',
      street: 'Street',
      service: 'mock-service',
    };

    cepPromise.mockResolvedValue(mockCepData);

    const { result } = renderHook(() => useCepInfo('12345-678'));

    let address;
    await act(async () => {
      address = await result.current.extraData;
    });

    expect(address).toEqual(mockCepData);
    expect(result.current.error).toBe('');
    expect(cepPromise).toHaveBeenCalledWith('12345678');
  });

  it('should return an error if fetching address fails', async () => {
    cepPromise.mockRejectedValue(new Error('Fetch failed'));

    const { result } = renderHook(() => useCepInfo('12345-678'));

    let address;
    await act(async () => {
      address = await result.current.extraData;
    });

    expect(address).toBeNull();
    expect(result.current.error).toBe('');
    expect(cepPromise).toHaveBeenCalledWith('12345678');
  });
});
