import React from 'react';
import { render, screen, fireEvent } from '@testing-library/react';
import { userEvent } from '@testing-library/user-event';
import { PhoneFormField } from '../PhoneFormField';

describe('PhoneFormField Component', () => {
  const mockOptions = [
    { value: 'us', label: 'United States', selected: true },
    { value: 'br', label: 'Brazil' },
  ];

  it('renders the component with default props', () => {
    render(<PhoneFormField options={mockOptions} />);
    expect(screen.getByText('Phone')).toBeInTheDocument();
    expect(screen.getByRole('combobox')).toBeInTheDocument();
    expect(screen.getByRole('textbox')).toBeInTheDocument();
  });

  it('renders the label correctly', () => {
    render(<PhoneFormField label="Custom Label" options={mockOptions} />);
    expect(screen.getByText('Custom Label')).toBeInTheDocument();
  });

  it('renders the select options correctly', () => {
    render(<PhoneFormField options={mockOptions} />);
    const select = screen.getByRole('combobox');
    expect(select).toHaveValue('us');
    expect(screen.getByText('United States')).toBeInTheDocument();
    expect(screen.getByText('Brazil')).toBeInTheDocument();
  });

  it('handles input changes', () => {
    render(<PhoneFormField options={mockOptions} />);
    const input = screen.getByRole('textbox');
    fireEvent.change(input, { target: { value: '1234567890' } });
    expect(input).toHaveValue('1234567890');
  });

  it('disables input and select when isReadOnly is true', () => {
    render(<PhoneFormField isReadOnly={true} options={mockOptions} />);
    expect(screen.getByRole('combobox')).toBeDisabled();

    fireEvent.change(screen.getByRole('textbox'), {
      target: { value: 'new value' },
    });

    expect(screen.getByRole('textbox')).toHaveValue('');
  });

  it('marks input and select as required when required is true', () => {
    render(<PhoneFormField required options={mockOptions} />);
    expect(screen.getByRole('combobox')).toBeRequired();
    expect(screen.getByRole('textbox')).toBeRequired();
  });

  it('applies the mask correctly', () => {
    render(<PhoneFormField mask="(999) 999-9999" options={mockOptions} />);
    const input = screen.getByRole('textbox');
    fireEvent.change(input, { target: { value: '1234567890' } });
    expect(input).toHaveValue('(123) 456-7890');
  });

  it('should allow user to change options', async () => {
    render(<PhoneFormField options={mockOptions} />);

    expect(
      screen.getByRole('option', { name: 'United States' }).selected,
    ).toBeTruthy();

    expect(screen.getByRole('option', { name: 'Brazil' }).selected).toBeFalsy();

    const combobox = screen.getByRole('combobox');

    await userEvent.selectOptions(combobox, ['br']);

    expect(
      screen.getByRole('option', { name: 'United States' }).selected,
    ).toBeFalsy();

    expect(
      screen.getByRole('option', { name: 'Brazil' }).selected,
    ).toBeTruthy();
  });
});
