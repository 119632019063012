import React, { useState } from 'react';
import { __ } from '../../../../i18n';
import InputMask from 'react-input-mask';
import { AngleDownLight, MobileLight } from '@classapp-tech/edna-icons';
import {
  PhoneContent,
  PhoneCountrySelection,
  PhoneFormDiv,
  PhoneFormLabel,
  PhoneIcon,
  PhoneInputDiv,
  PhoneSelect,
  PhoneFormInput,
  SelectIcon,
} from './PhoneFormFieldStyle';

export function PhoneFormField({
  label = 'Phone',
  name = 'phone',
  options = [],
  mask = null,
  placeholder = null,
  required = false,
  isReadOnly = false,
  value = '',
}) {
  const [inputValue, setValue] = useState(value);

  const handleInputChange = (e) => {
    if (isReadOnly) return;
    setValue(e.target.value);
  };

  return (
    <PhoneFormDiv>
      <PhoneFormLabel>{__(label)}</PhoneFormLabel>
      <PhoneContent isReadOnly={isReadOnly}>
        <PhoneCountrySelection>
          <PhoneSelect
            name={'select' + name}
            id={'select' + name}
            disabled={isReadOnly}
            required={required}
            defaultValue={options.find((option) => option.selected)?.value}
          >
            {options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
                hidden={option.hidden}
              >
                {__(option.label)}
              </option>
            ))}
          </PhoneSelect>
          <SelectIcon>
            <AngleDownLight />
          </SelectIcon>
        </PhoneCountrySelection>
        <PhoneInputDiv>
          <PhoneIcon>
            <MobileLight />
          </PhoneIcon>
          <InputMask
            mask={mask}
            readOnly={isReadOnly}
            disabled={isReadOnly}
            value={inputValue}
            onChange={handleInputChange}
          >
            {() => (
              <PhoneFormInput
                type="text"
                placeholder={placeholder}
                name={'input' + name}
                id={'input' + name}
                value={inputValue}
                readOnly={isReadOnly}
                disabled={isReadOnly}
                onChange={handleInputChange}
                required={required}
              />
            )}
          </InputMask>
        </PhoneInputDiv>
      </PhoneContent>
    </PhoneFormDiv>
  );
}
