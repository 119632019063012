import { __ } from '../../../../../i18n.js';

/**
 * Custom React hook to validate a card expiration date.
 *
 * @param {string} cardDate - The card expiration date to validate in MM/YY format.
 * @returns {{value: string, error: string}} - An object containing the card date value and an error message if invalid.
 *
 * This hook checks if the provided card expiration date is valid.
 * It returns an object with the card date value and an error message if the card date is invalid.
 * The error message is an empty string if the card date is valid.
 */
export function useCardDateValidation(cardDate) {
  const isValidDate = (date) => {
    const [month, year] = date.split('/').map((part) => parseInt(part, 10));
    if (!month || !year || month < 1 || month > 12) return false;

    const currentDate = new Date();
    const currentYear = parseInt(
      currentDate.getFullYear().toString().slice(-2),
      10,
    );
    const currentMonth = currentDate.getMonth() + 1;

    return (
      year > currentYear || (year === currentYear && month >= currentMonth)
    );
  };

  const error =
    cardDate.replace(/\/|\_/g, '') && !isValidDate(cardDate)
      ? __('Invalid expiration date')
      : '';

  return { value: cardDate, error };
}
