import { __ } from '../../../../../i18n.js';

/**
 * Custom React hook to validate a CVV code.
 *
 * @param {string} cvv - The CVV code to validate.
 * @returns {{value: string, error: string}} - An object containing the CVV value and an error message if invalid.
 *
 * This hook checks if the provided CVV is valid based on its length (3 or 4 digits) and numeric format.
 * It returns an object with the CVV value and an error message if the CVV is invalid.
 * The error message is an empty string if the CVV is valid.
 */
export function useCvvValidation(cvv) {
  const isValidCvv = (code) => {
    const cvvRegex = /^[0-9]{3,4}$/;
    return cvvRegex.test(code);
  };

  const formatedCvv = cvv.replace(/\_|\s/g, '');

  const error =
    formatedCvv && !isValidCvv(formatedCvv) ? __('Invalid cvv number') : '';

  return { value: cvv, error };
}
