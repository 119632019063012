import { useEffect, useState } from 'react';

/**
 * Custom React hook to debounce a value.
 *
 * @param {string | number} value - The value to debounce.
 * @param {number} delay - The debounce delay in milliseconds.
 * @returns {string | number} - The debounced value.
 *
 * This hook delays updating the returned value until after the specified delay
 * has passed since the last time the input value was changed. It is useful for
 * scenarios like debouncing user input in search fields.
 */
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}