import { renderHook } from '@testing-library/react-hooks';
import { useCardNumberValidation } from '../../hooks/cardNumberValidation';
import { __ } from '../../../../../../i18n.js';

const invalidCardNumber = __('Invalid card number');

describe('useCardNumberValidation', () => {
  it('should return no error for a valid card number', () => {
    const { result } = renderHook(() =>
      useCardNumberValidation('4532 0151 1283 0366'),
    );
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('4532 0151 1283 0366');
  });

  it('should return an error for an invalid card number', () => {
    const { result } = renderHook(() =>
      useCardNumberValidation('1234 5678 9012 3456'),
    );
    expect(result.current.error).toBe(invalidCardNumber);
    expect(result.current.value).toBe('1234 5678 9012 3456');
  });

  it('should return no error for an empty card number', () => {
    const { result } = renderHook(() => useCardNumberValidation(''));
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('');
  });

  it('should handle non-numeric input gracefully', () => {
    const { result } = renderHook(() =>
      useCardNumberValidation('abcd 1234 4312 dcba'),
    );
    expect(result.current.error).toBe(invalidCardNumber);
    expect(result.current.value).toBe('abcd 1234 4312 dcba');
  });

  it('should return no error form not filled card number', () => {
    const { result } = renderHook(() => useCardNumberValidation('abcd1234'));
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('abcd1234');
  });

  it('should return no error when mask is not completed', () => {
    const { result } = renderHook(() =>
      useCardNumberValidation('1234 4321 ____ ____'),
    );
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('1234 4321 ____ ____');
  });
});
