import React, { useState, useEffect } from 'react';
import {
  InputDiv,
  TextFormLabel,
  TextFormDiv,
  TextFormInput,
  Tooltip,
  TooltipText,
} from './TextFormFieldStyle';
import { TriangleExclamationLight } from '@classapp-tech/edna-icons';
import { useDebounce } from './hooks/debounce';
import { __ } from '../../../../i18n';
import InputMask from 'react-input-mask';

/**
 * TextFormField component renders a labeled input field with validation error tooltip.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.label - The label for the input field.
 * @param {boolean} props.required - Whether the input field is required.
 * @param {boolean} props.isReadOnly - Whether the input field is read-only.
 * @param {string} props.name - The name attribute for the input field.
 * @param {function | null} props.hook - A hook function for custom validation.
 * @param {string} props.value - The initial value of the input field.
 * @param {string | null} props.mask - Mask of the input field.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {function | null} props.onChange - A function to handle changes.
 * @returns {JSX.Element} The rendered TextFormField component.
 */
export function TextFormField({
  label,
  required,
  isReadOnly,
  name,
  hook = null,
  value = '',
  mask = null,
  placeholder,
  onChange = null,
}) {
  const [error, setError] = useState('');
  const [inputValue, setValue] = useState(value);
  const debouncedValue = useDebounce(inputValue, 500);

  useEffect(() => {
    let extraData = null;
    if (hook) {
      const hookResult = hook(debouncedValue);
      setValue(hookResult.value || inputValue);
      setError(hookResult.error || '');
      extraData = hookResult.extraData || null;
    }
    if (onChange) onChange(extraData);
  }, [debouncedValue]);

  /**
   * Handles input change event.
   *
   * @param {Object} e - The event object.
   */
  const handleInputChange = (e) => {
    if (isReadOnly) return;
    setValue(e.target.value);
  };

  return (
    <TextFormDiv>
      <TextFormLabel error={!!error} htmlFor={name}>
        {__(label)}
      </TextFormLabel>
      <InputDiv>
        <InputMask
          mask={mask}
          value={inputValue}
          onChange={handleInputChange}
          readOnly={isReadOnly}
        >
          {() => (
            <TextFormInput
              error={!!error}
              isReadOnly={isReadOnly}
              type="text"
              placeholder={placeholder}
              name={name}
              id={name}
              value={inputValue}
              readOnly={isReadOnly}
              required={required}
              onChange={handleInputChange}
            />
          )}
        </InputMask>
        {error && (
          <Tooltip>
            <TriangleExclamationLight
              fill="#A02000"
              role="img"
              aria-label="SVG warning icon"
            />
            <TooltipText>{error}</TooltipText>
          </Tooltip>
        )}
      </InputDiv>
    </TextFormDiv>
  );
}