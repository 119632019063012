import { renderHook } from '@testing-library/react-hooks';
import { useCvvValidation } from '../../hooks/cvvValidation';
import { __ } from '../../../../../../i18n.js';

const invalidCvv = __('Invalid cvv number');

describe('useCvvValidation', () => {
  it('should return no error for a valid 3-digit CVV', () => {
    const { result } = renderHook(() => useCvvValidation('123_'));
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('123_');
  });

  it('should return no error for a valid 4-digit CVV', () => {
    const { result } = renderHook(() => useCvvValidation('1234'));
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('1234');
  });

  it('should return an error for an invalid CVV with non-numeric characters', () => {
    const { result } = renderHook(() => useCvvValidation('12a4'));
    expect(result.current.error).toBe(invalidCvv);
    expect(result.current.value).toBe('12a4');
  });

  it('should return an error for a CVV with less than 3 digits', () => {
    const { result } = renderHook(() => useCvvValidation('12'));
    expect(result.current.error).toBe(invalidCvv);
    expect(result.current.value).toBe('12');
  });

  it('should return an error for a CVV with more than 4 digits', () => {
    const { result } = renderHook(() => useCvvValidation('12345'));
    expect(result.current.error).toBe(invalidCvv);
    expect(result.current.value).toBe('12345');
  });

  it('should return no error for an empty CVV', () => {
    const { result } = renderHook(() => useCvvValidation(''));
    expect(result.current.error).toBe('');
    expect(result.current.value).toBe('');
  });

  it('should handle masked input gracefully', () => {
    const { result } = renderHook(() => useCvvValidation('12__'));
    expect(result.current.error).toBe(invalidCvv);
    expect(result.current.value).toBe('12__');
  });
});
