import styled, { css } from 'styled-components';

export const TextFormLabel = styled.label`
  font-family: Circular Std;
  font-size: 1rem;
  font-weight: 700;
  line-height: 140%;
  color: #212529;

  ${({ error }) =>
    error &&
    css`
      color: #a02000;
    `}
`;

export const InputDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const TextFormDiv = styled.div`
  display: flex;
  width: 306px;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TextFormInput = styled.input`
  height: 48px;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  border-radius: 10px;
  font-family: Circular Std;
  font-size: 1rem;
  font-weight: 450;

  ${({ isReadOnly }) =>
    isReadOnly &&
    css`
      background-color: #E5E5E5 !important;
      cursor: not-allowed !important;
      color: #616161 !important;
  `}

  ${({ error }) =>
    error &&
    css`
      color: #a02000;
      background-color: #ffebe6;
      border-color: #bf2600;
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
  `}

  padding: 1rem;

  &:focus {
    outline: none;
  }
`;

export const Tooltip = styled.div`
  min-width: 3rem;
  height: 3rem;
  border-style: solid;
  position: relative;
  display: inline-block;
  place-items: center;
  left: -1px;
  border-width: 1px;
  border-color: #bf2600;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  padding: 14px;
`;

export const TooltipText = styled.span`
  visibility: hidden;
  min-width: 8.5rem;
  min-height: 2rem;
  background-color: white;
  color: black;
  font-family: Circular Std;
  font-size: 12px;
  font-weight: 450;
  text-align: center;
  border-radius: 8px;
  padding: 4px 16px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: -45px;

  ${Tooltip}:hover & {
    visibility: visible;
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;